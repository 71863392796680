<template>
  <b-list-group>
    <b-list-group-item class="p-0">
      <b-button @click="refresh()" block size="sm" :disabled="isLoading"
        >Fresh</b-button
      >
    </b-list-group-item>
    <b-list-group-item v-for="stock in stocks" :key="stock" class="py-0 px-1">
      <b-row>
        <b-col cols="12" sm="8">
          <b-button
            @click="gotoFinviz(stock)"
            variant="outline"
            block
            class="text-left"
          >
            <strong>
              {{ stock }}
            </strong>
            <br />
            <small
              >${{
                (infoList[stock].marketCap / 1000000000).toFixed(2)
              }}
              b</small
            ><br />
            <small class="text-muted"> {{ infoList[stock].companyName }} </small
            ><br />
          </b-button>
        </b-col>

        <b-col cols="12" sm="4" class="text-right">
          <span v-if="infoList[stock].changePercent > 0" class="text-success"
            >${{ infoList[stock].latestPrice }}
          </span>
          <span v-if="infoList[stock].changePercent < 0" class="text-danger"
            >${{ infoList[stock].latestPrice }}
          </span>
          <br />
          <span v-if="infoList[stock].changePercent > 0" class="text-success"
            >{{ (infoList[stock].changePercent * 100).toFixed(2) }} %</span
          >
          <span v-else class="text-danger"
            >{{ (infoList[stock].changePercent * 100).toFixed(2) }} %</span
          >
          <br />
          {{
            (infoList[stock].latestPrice / infoList[stock].week52Low).toFixed(2)
          }}
          -
          {{
            (infoList[stock].latestPrice / infoList[stock].week52High).toFixed(
              2
            )
          }}x
        </b-col>
      </b-row>
    </b-list-group-item>
  </b-list-group>
</template>

<script>

export default {
  props: ["stocks", "infoList","isLoading"],
  data: function() {
    return {
      finviz: "https://www.finviz.com/quote.ashx?t=",
    };
  },
  created: function() {},
  methods: {
    gotoFinviz(stock) {
      window.open(`https://www.finviz.com/quote.ashx?t=${stock}`, "_blank");
    },
    refresh() {
      this.$emit("refresh");
    }
  }
};
</script>
