<template>
  <div>
    <h1>Stock Graph</h1>
    <hr />
    <div>
      <b-form inline>
        <b-form-group>
          <b-input
            class="mr-4 "
            v-model="initStock"
            description="reset then run, "
          ></b-input>
          <b-button
            class="mr-4"
            :disabled="!this.initStock || isLoading"
            @click="setStock()"
            >Begin</b-button
          >

          <b-button
            @click="getRelatedStock()"
            :disabled="!this.initStock || isLoading"
            >Fetch All Mode</b-button
          >
        </b-form-group>
      </b-form>
    </div>
    <br />
    <b-row>
      <b-col cols="12" sm="3">
        <stock-list
          :stocks="visitedStocks"
          :infoList="infoList"
          @refresh="refresh()"
          :isLoading="isLoading"
        ></stock-list>
      </b-col>
      <b-col cols="12" sm="9" class="bg-light">
        <b-form>
          <b-form-group label="distance">
            <b-input
              v-model="force"
              type="range"
              min="100"
              max="6000"
              step="100"
            ></b-input>
          </b-form-group>
          <b-form-group label="market size scale">
            <b-input
              v-model="marketScale"
              type="range"
              min="10"
              max="100"
              step="5"
            ></b-input>
          </b-form-group>
        </b-form>
        <D3Network
          :net-nodes="nodes"
          :net-links="links"
          :options="options"
          @node-click="nodeClick"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import D3Network from "vue-d3-network";
import axios from "axios";
import StockList from "../components/stockList";
export default {
  components: { D3Network, StockList },
  data: function() {
    return {
      error: "",
      isLoading: false,
      initStock: "ZM",
      force: 3000,
      marketScale: 50,
      visitedStocks: [],
      infoList: {},
      todoStocks: [],
      nodeSizeMin: 5,
      nodeSizeMax: 100,
      nodes: [],
      links: [] //{ sid: 4, tid: 5 },
    };
  },
  computed: {
    options() {
      return {
        force: this.force,
        size: { w: 1800, h: 1024 },
        nodeSize: this.nodeSize,
        nodeLabels: true,
        nodeLabelSize: "40em",
        linkLabels: true,
        canvas: this.canvas
      };
    }
  },
  methods: {
    async refresh() {
      this.isLoading=true
      for(let stock of this.visitedStocks){
        let res = await axios.get('/.netlify/functions/getStockInfo/', {
          params: {stock: stock}
        })
        this.infoList[stock]=res.data
      }
      this.isLoading=false
    },

    setNodeColor(change) {
      if (change > 0) {
        let r = 100;
        let g = 100 * (1 + 100 * change);
        //rgb(41, 189, 47)
        return `rgb(${r},${g},120)`;
      } else {
        let r = 100 * (1 - 100 * change);
        let g = 100;
        //rgb(41, 189, 47)
        return `rgb(${r},${g}, 100)`;
      }
    },

    async createNode(stock) {
      console.log(stock)
      try{
      let node = {};
      node.id = stock;
      node.name = stock;
      let res = await axios.get("/.netlify/functions/getStockInfo/", {
        params: { stock: stock }
      });
      node._color = this.setNodeColor(res.data.changePercent);

      node._size = (Math.log10(res.data.marketCap) - 10) * this.marketScale;
      node._size = node._size > this.nodeSizeMin ? node._size : this.nodeSizeMin;
      node._size = node._size < this.nodeSizeMax ? node._size : this.nodeSizeMax;
      /*
      node._svgAttrs={
        'stroke-width': 0,
      }
      */
      this.infoList[stock] = res.data;
      return node;
      } catch(err){
        console.log(err)
      }
    },

    async nodeClick(event, node) {
      if (this.isLoading) return;
      this.isLoading = true;
      if (this.visitedStocks.indexOf(node.id) == -1) {
        this.visitedStocks.push(node.id);
        let res = await axios.get("/.netlify/functions/getRelatedStock/", {
          params: { stock: node.id }
        });
        for (let relatedStock of res.data) {
          {
            if (
              this.nodes.findIndex(e => {
                return e.id == relatedStock;
              }) == -1
            )
              this.nodes.push(await this.createNode(relatedStock));
          }
          this.links.push({ sid: node.id, tid: relatedStock });
        }
      }
      this.isLoading = false;
    },

    async getRelatedStock() {
      let count = this.todoStocks.length;
      while (count > 0) {
        this.isLoading = true;
        let currentStock = this.todoStocks.shift();
        this.visitedStocks.push(currentStock);
        let res = await axios.get("/.netlify/functions/getRelatedStock/", {
          params: { stock: currentStock }
        });
        let newNode={}
        for (let relatedStock of res.data) {
          if (
            this.nodes.findIndex(e => {
              return e.name == relatedStock;
            }) == -1
          ) {
            newNode = await this.createNode(relatedStock)
            if(newNode){
              this.nodes.push(await this.createNode(relatedStock));
              this.todoStocks.push(relatedStock);
            }
          }
          if(newNode)
            this.links.push({ sid: currentStock, tid: relatedStock });
        }
        count = count - 1;
      }
      this.isLoading = false;
    },

    setStock: async function() {
      this.initStock = this.initStock.toUpperCase();
      this.todoStocks = [];
      this.todoStocks.push(this.initStock);
      this.visitedStocks = [];
      this.nodes = [];
      this.nodes.push(await this.createNode(this.initStock));
      this.links = [];
    }
  },

  created() {},

  watch: {
    marketScale: function(newValue, oldValue) {
      for (let n of this.nodes) {
        n._size = (n._size * newValue) / oldValue;
      }
    }
  }
};
</script>
<style src="vue-d3-network/dist/vue-d3-network.css"></style>
